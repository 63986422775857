import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import TextPage from '../components/text-page';

const ExecutiveBoardOfDab = () => {
  const intl = useIntl();
  const messages = intl.messages;

  return (
    <TextPage locale={intl.locale} title={`${messages.executive_board_dab}`} otherElements={
      <div className="ui centered grid container">
        <div className="four wide column">
          <div className={"ui card " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
            <div className="content">
              <a className="header">{messages.dab_md}</a>
              <div className="description">
                {messages.acting_governor_of_dab}
              </div>
            </div>
          </div>
        </div>
      </div>
    }/>
  );
};

export default ExecutiveBoardOfDab;